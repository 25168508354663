import { global } from "../constants";

export const data = {
  banner: {
    background: global.banner + "about4.png",
    title: "",
    crumb: "About Us",
  },

  whatwedo: {
    title: "What we do!",
    subtitle: "Specialist Logistics Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    image: global.icons + "img3.png",
    works: [
      {
        icon: global.icons + "warehouse.png",
        serial: "01.",
        title: "Warehousing",
        description:
          "Managing warehouse to deliver packets through 3W EV & 4W EV , with our team (Supervisors, Managers, Drivers, Delivery boys).",
      },
      {
        icon: global.icons + "corporates.png",
        serial: "02.",
        title: "On demand",
        description:
          "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys).",
      },
      {
        icon: global.icons + "ondemand.png",
        serial: "03.",
        title: "Corporate",
        description:
          "On Demand Platform for B2B customers assisting in their deliveries through 100% EV Fleet around with 600+ happy customers.",
      },
    ],
  },

  achivements: [
    {
      count: "2",
      title: "Warehouses in India",
    },
    {
      count: "4+",
      title: "Year of experience",
    },
    {
      count: "100+",
      title: "Team members",
    },
  ],

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/1.png",
      global.logo + "/3.png",
      global.logo + "/6.png",
      global.logo + "/5.png",
      global.logo + "/8.png",
      global.logo + "/9.png",
    ],
    partners: [
      {
        pic: global.testim + "agarwal1.png",
        quote:
          "Pickkup has exceeded our expectations for transportation services. Their last-mile delivery solution has helped us streamline our operations and reduce delivery times significantly. The fact that they use electric vehicles aligns with our sustainability goals, making it a win-win for our business and the environment.",
        name: "Aggarwal Plywood",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "exotic1.png",
        quote:
          "The ease of working with Pickkup is remarkable. Their EV fleet ensures timely deliveries for our e-commerce platform, and we love their commitment to sustainability. The team is always professional, and the integration into our system has been smooth. We’re proud to be partnering with such an innovative company",
        name: "Exotic Healthcare",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "milano1.png",
        quote:
          "Pickkup has completely transformed our logistics operations. Their EV-based delivery service is not only eco-friendly but also incredibly efficient. We've seen a significant improvement in delivery speed and customer satisfaction. Their team is professional, always punctual, and the service is reliable. Highly recommended for businesses looking for sustainable last-mile delivery solutions",
        name: "Milano Kitchens",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "AV1.png",
        quote:
          "We’ve been using Pickkup for months, and the experience has been fantastic. Their professionalism and commitment to meeting deadlines are impressive. The EV fleet is a great addition, helping us cut costs while reducing our environmental impact.",
        name: "AV Metals",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "MG1.png",
        quote:
          "We’ve been relying on Pickkup for last-mile delivery, and they’ve consistently delivered excellence. Their EV fleet is not only cost-effective but also helps us meet our sustainability goals. The team is professional, punctual, and always goes the extra mile to meet our needs.",
        name: "MG Enterprises",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "MS1.png",
        quote:
          "We’ve been using Pickkup for our B2B transportation needs, and the experience has been seamless. Their electric vehicles are perfect for reducing our carbon footprint while still meeting our deadlines. The service is cost-effective and the customer support team is responsive, which makes a big difference in managing deliveries.",
        name: "MS Industries",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "pharma1.png",
        quote:
          "The Pickkup team is exceptional! Their warehousing solutions have streamlined our inventory management, and their last-mile delivery has improved our overall logistics. The team is responsive, and their service is both cost-effective and sustainable. Couldn’t ask for a better logistics partner",
        name: "RS Box",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "Puneet1.png",
        quote:
          "Pickkup’s services have been invaluable to our business. Their electric vehicles ensure fast and eco-friendly last-mile deliveries, which has boosted our customer satisfaction. Their warehousing and bulk transportation solutions are efficient and have made our logistics more seamless. A truly professional and reliable team!",
        name: "Puneet Trading",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "RS1.png",
        quote:
          "Working with Pickkup has been smooth from day one. Their service is reliable, and we love their electric vehicle fleet, which aligns with our green initiatives. Their ability to provide timely last-mile delivery has greatly improved our delivery operations",
        name: "AAR ESS EnterPrises",
        designation: "B2B Client",
      },
      {
        pic: global.testim + "88-woods.png",
        quote:
          "Pickkup last-mile delivery is dependable and always on time, helping us keep our customers satisfied. We also love their use of electric vehicles, which supports our sustainability goals. It's a highly efficient and affordable service that we strongly recommend!",
        name: "88 Woods",
        designation: "B2B Client",
      },
    ],
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title:
          "At the end of the day, going forward, a new normal that has evolved from.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title:
          "How all this mistaken idea denouncing pleasure and praising pain was.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit.",
      },
    ],
  },
  founders: {
    title: "",
    subtitle: "Founding Team",
    description: "",
    blogs: [
      {
        image: global.blog1 + "CEO.png",

        month: "CEO",
        title: "Ankush Sharma",
        description: "",
        linkedinUrl: "https://www.linkedin.com/in/ankush-sharma-5744311a9/",
      },
      {
        image: global.blog1 + "CTO.png",

        month: "CTO",
        title: "Chandra Shekhar",
        description: "",
        linkedinUrl: "https://www.linkedin.com/in/chandra-shekhar-8208603/",
      },
      {
        image: global.blog1 + "COO.png",

        month: "COO",
        title: "Upma Sharma",
        description: "",
        linkedinUrl: "https://www.linkedin.com/in/upma-sharma-134680303/",
      },
    ],
  },

  approach: {
    title: "Company Approach",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "4+",
    specifications: [
      {
        icon: global.icons + "4.png",
        count: "150000",
        title: "Green KMs",
      },
      {
        icon: global.icons + "vehicle.png",
        count: "97000",
        title: "On Time Deliveries",
      },
      {
        icon: global.icons + "3.png",
        count: "600",
        title: "Individual Customers",
      },
    ],
  },
};
