import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";

export default function Footer1() {
  return (
    <>
      <footer className="footer-dark">
        {/* NEWS LETTER SECTION START */}
        <div className="ftr-nw-ltr site-bg-white">
          <div className="ftr-nw-ltr-inner site-bg-primary">
            <div className="container">
              <div className="ftr-nw-img">
                <img
                  src={publicUrlFor("/assets/images/newsletter.png")}
                  alt="#"
                />
              </div>
              <div className="ftr-nw-content">
                <div className="ftr-nw-title">
                  Subscribe for offers and news
                </div>
                <div className="ftr-nw-form">
                  <form>
                    <input
                      name="news-letter"
                      className="form-control"
                      placeholder="Enter Your Email"
                      type="text"
                    />
                    <button className="ftr-nw-subcribe-btn">
                      Subscribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* NEWS LETTER SECTION END */}

        <div
          className="ftr-bg"
          style={{
            backgroundImage: `url(${publicUrlFor(
              "/assets/images/footer.jpg"
            )})`,
          }}
        >
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/">
                        <img
                          src={publicUrlFor("/assets/images/pickkup_logo.png")}
                          alt="#"
                        />
                      </NavLink>
                      <p class="small-text">3CAS Logistics Solutions PVT Ltd</p>


                    </div>
                    <p>
                      Full Stack Mid and last mile logistics service provider
                      with 100% EV Fleet in 3W & 4W
                    </p>
                    <ul className="social-icons">
                      <li>
                        <a
                          href="https://www.facebook.com/pickkup.io"
                          className="fa fa-facebook"
                          target="_blank"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/pickkup-io/mycompany/"
                          className="fa fa-linkedin"
                          target="_blank"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/pickkup.io/"
                          className="fa fa-instagram"
                          target="_blank"
                        >
                          {" "}
                        </a>
                      </li>
                      {/* <li><a href="https://www.youtube.com/" className="fa fa-youtube-play" target="_blank"> </a></li> */}
                    </ul>
                   
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <NavLink to="/about-us">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/services2">Services</NavLink>
                      </li>
                      {/* <li><NavLink to="/blogs/grid">Blog</NavLink></li> */}
                      {/* <li><NavLink to="/faq">FAQ</NavLink></li> */}
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                      <li>
                        On Demand (Customer)
                      </li>
                      <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.pickkup.customer"
                        target="_blank"
                      >
                        <img
                          src={publicUrlFor("/assets/images/googlePlay.png")}
                          alt="Get it on Google Play"
                          width="140px"
                        />
                      </a>
                      </li>
                    </ul>
                    
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Services</h3>
                    <ul>
                      <li>
                        <NavLink>Warehouse</NavLink></li>
                      <li><NavLink>
                      On Demand</NavLink></li>
                      <li><NavLink>Corporate</NavLink></li>
                      <li>
                        On Demand (Drivers)
                      </li>
                      <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.pickkup.drivers"
                        target="_blank"
                      >
                        <img
                          src={publicUrlFor("/assets/images/googlePlay.png")}
                          alt="Get it on Google Play"
                           width="140px"
                        />
                      </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Community</h3>
                    <ul>
                      <li>
                        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Terms-condition">
                          Terms & Condition
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/refund-policy">
                          Refund Policy
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}

          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-info">
                <div className="footer-copy-right">
                  <span className="copyrights-text">
                    Copyright © 2024 by Pickkup All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
