import { global } from "../constants";

export const data = {
  whychooseus: {
    title: "Why Choose Us",
    subtitle: "We Are End-to-End Logistics solutions provider",
    description: {
      short:
        "Pickkup goes beyond transportation—we provide seamless logistics solutions tailored to your needs. Specializing in last-mile delivery, bulk transportation, and warehousing, we ensure your operations run smoothly and efficiently.",
      long: "Our fleet of electric vehicles delivers eco-friendly, cost-effective services, helping you reduce your carbon footprint while improving delivery performance. Trusted by top B2B clients, we guarantee timely, secure, and professional service. Pickkup is your partner for reliable, future-ready solutions.",
    },
  },

  whatwedo: {
    title: "What we do!",
    subtitle: "Specialist Logistics Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    works: [
      {
        icon: global.icons2 + "pic1.png",
        serial: "01",
        title: "Warehousing",
        description:
          "Managing warehouse to deliver packets through 3W EV & 4W EV , with our team (Supervisors, Managers, Drivers, Delivery boys).",
      },
      {
        icon: global.icons2 + "pic1.png",
        serial: "02",
        title: "Corporates",
        description:
          "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys).",
      },
      {
        icon: global.icons2 + "pic1.png",
        serial: "03",
        title: "On Demand (B2B Customers)",
        description:
          "On Demand Platform for B2B customers assisting in their deliveries through 100% EV Fleet around with 600+ happy customers.",
      },
    ],
  },

  estimation: {
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description:
      "Our EV-based logistics company offers sustainable, eco-friendly transportation solutions with a wide range of services, including last-mile delivery, fleet management, and smart logistics.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized",
        description:
          "An EV-based logistics company specializes in providing eco-friendly transportation and delivery services using electric vehicles.",
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        description:
          "We provide multiple drop-off and pickup locations, so you don't have to worry. You won't face any inconvenience.",
      },
      {
        serial: "03",
        title: "Tracking made easy",
        description:
          "A tracking number for the entire process. so that you can find the exact position. ",
      },
    ],
  },

  services: {
    title: "All services",
    subtitle: "Trusted For Our Services",
    description:
      "EV Logistics is trusted for delivering sustainable solutions through our core services: eco-friendly warehousing, on-demand B2B customer support, and tailored corporate logistics.",
    services: [
      {
        title: "Warehousing",
        serial: "01",
        description:
          "Managing warehouse to deliver packets through 3W EV & 4W EV , with our team (Supervisors, Managers, Drivers, Delivery boys).",
      },
      {
        title: "Corporates",
        serial: "02",
        description:
          "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys).",
      },
      {
        title: "On Demand",
        serial: "03",
        description:
          "On Demand Platform for B2B customers assisting in their deliveries through 100% EV Fleet around with 600+ happy customers.",
      },
    ],
  },

  testimonials: {
    title: "Testimonials",
    subtitle: "Client Says",
    quotes: [
      {
        image: global.testim + "88-woods.png",
        quote:
          "Pickkup last-mile delivery is dependable and always on time, helping us keep our customers satisfied. We also love their use of electric vehicles, which supports our sustainability goals. It's a highly efficient and affordable service that we strongly recommend!",
        name: "MG Enterprises",
        designation: "Web Designer",
      },
      {
        image: global.testim + "AV-metals.png",
        quote:
          "We’ve been using Pickkup for months, and the experience has been fantastic. Their professionalism and commitment to meeting deadlines are impressive. The EV fleet is a great addition, helping us cut costs while reducing our environmental impact.",
        name: "88 Woods",
        designation: "Developer",
      },
      {
        image: global.testim + "Pharma.png",
        quote:
          "The Pickkup team is exceptional! Their last-mile delivery has improved our overall logistics. The team is responsive, and their service is both cost-effective and sustainable. Couldn’t ask for a better logistics partner",
        name: "AV Metals",
        designation: "Web Designer",
      },
      {
        image: global.testim + "RS.png",
        quote:
          "Working with Pickkup has been smooth from day one. Their bulk transportation service is reliable, and we love their electric vehicle fleet, which aligns with our green initiatives. Their ability to provide timely last-mile delivery has greatly improved our delivery operations",
        name: "RS Box",
        designation: "Developer",
      },
      {
        image: global.testim + "milano.png",
        quote:
          "Pickkup has completely transformed our logistics operations. Their EV-based delivery service is not only eco-friendly but also incredibly efficient. We've seen a significant improvement in delivery speed and customer satisfaction. Their team is professional, always punctual, and the service is reliable. Highly recommended for businesses looking for sustainable last-mile delivery solutions",
        name: "AAR ESS EnterPrises",
        designation: "Developer",
      },
      {
        image: global.testim + "MS-industry.png",
        quote:
          "We’ve been using Pickkup for our B2B transportation needs, and the experience has been seamless. Their electric vehicles are perfect for reducing our carbon footprint while still meeting our deadlines. The service is cost-effective and the customer support team is responsive, which makes a big difference in managing deliveries.",
        name: "Milano Kitchens",
        designation: "Developer",
      },
      {
        image: global.testim + "exotic.png",
        quote:
          "The ease of working with Pickkup is remarkable. Their EV fleet ensures timely deliveries for our e-commerce platform, and we love their commitment to sustainability. The team is always professional, and the integration into our system has been smooth. We’re proud to be partnering with such an innovative company",
        name: "MS Industries",
        designation: "Developer",
      },
      {
        image: global.testim + "agarwal.png",
        quote:
          "Pickkup has exceeded our expectations for transportation services. Their last-mile delivery solution has helped us streamline our operations and reduce delivery times significantly. The fact that they use electric vehicles aligns with our sustainability goals, making it a win-win for our business and the environment.",
        name: "Exotic Healthcare",
        designation: "Developer",
      },
      {
        image: global.testim + "puneet.png",
        quote:
          "Pickkup’s services have been invaluable to our business. Their electric vehicles ensure fast and eco-friendly last-mile deliveries, which has boosted our customer satisfaction. Their warehousing and bulk transportation solutions are efficient and have made our logistics more seamless. A truly professional and reliable team!",
        name: "Aggarwal Plywood",
        designation: "Developer",
      },
      {
        image: global.testim + "MG.png",

        quote:
          "We’ve been relying on Pickkup for  last-mile delivery, and they’ve consistently delivered excellence. Their EV fleet is not only cost-effective but also helps us meet our sustainability goals. The team is professional, punctual, and always goes the extra mile to meet our needs.",
        name: "Puneet Trading",
        designation: "Developer",
      },
    ],
    clients: [
      global.logo + "1.png",
      global.logo + "2.png",
      global.logo + "3.png",
      global.logo + "4.png",
      global.logo + "5.png",
      global.logo + "6.png",
      global.logo + "7.png",
      global.logo + "8.png",
      global.logo + "9.png",
      global.logo + "10.png",
      global.logo + "11.png",
      global.logo + "12.png",
      global.logo + "1.png",
      global.logo + "2.png",
      global.logo + "3.png",
      global.logo + "4.png",
      global.logo + "5.png",
      global.logo + "6.png",
      global.logo + "7.png",
      global.logo + "8.png",
      global.logo + "9.png",
      global.logo + "10.png",
      global.logo + "11.png",
      global.logo + "12.png",
    ],
  },

  vehicles: {
    title: "Our Fleets",
    subtitle: "3W EV & 4W EV",
    description:
      "EV Logistics is trusted for delivering sustainable solutions through our core services: eco-friendly warehousing, on-demand B2B customer support, and tailored corporate logistics.",
    vehicles: [
      {
        title: "Log 9 (3W)",
        serial: "",
        image: global.fleet + "1.png",
        // description: "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys)."
      },
      {
        title: "11 Feet (Closed Body)",
        serial: "",
        image: global.fleet + "2.png",
      },
      {
        title: "11 Feet (Open Body)",
        serial: "",
        image: global.fleet + "3.png",
      },
    ],
  },
  approch: {
    title: "Company Approch",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "4+",
    solutions: [
      {
        icon: global.icons + "4.png",
        count: "150000",
        title: "Green KMs",
      },
      {
        icon: global.icons + "vehicle.png",
        count: "97000",
        title: "On Time Deliveries",
      },
      {
        icon: global.icons + "3.png",
        count: "600",
        title: "Individual Customers",
      },
    ],
  },

  booking: {
    background: global.booking + "bg-map.png",

    imageBack: global.booking + "truck-book2.png",
    title: "Our Locations",
    subtitle: "We provide our services in the following cities",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png",
    },
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        day: "05",
        month: "Aug",
        title: "Go beyond logistics, make the world go round and revolutionize",
        description:
          "New chip traps clusters of migrating are tumor cells  asperiortenetur, blanditiis odit.",
      },
      {
        day: "07",
        month: "Aug",
        title: "Go beyond logistics, make the world go round and revolutionize",
        description:
          "New chip traps clusters of migrating are tumor cells  asperiortenetur, blanditiis odit.",
      },
      {
        day: "09",
        month: "Aug",
        title: "Go beyond logistics, make the world go round and revolutionize",
        description:
          "New chip traps clusters of migrating are tumor cells  asperiortenetur, blanditiis odit.",
      },
    ],
  },

  trackk: {
    title: "Trackk",
    subtitle: "Has a wide range of solutions",
    description:
      "",
   
  },
};
