import { global } from "../constants";

export const data = {
  banner: {
    background: global.banner + "service7.png",
    // title: "Our Services Two",
    crumb: "Our Services",
  },

  services: {
    title: "All services",
    subtitle: "Trusted For Our Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    services: [
      {
        icon: global.icons + "warehouse.png",
        serial: "01",
        title: "Warehousing",
        description:
          "Managing warehouse to deliver packets through 3W EV & 4W EV , with our team (Supervisors, Managers, Drivers, Delivery boys).",
      },
      {
        icon: global.icons + "corporates.png",
        serial: "02",
        title: "Corporates",
        description:
          "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys).",
      },
      {
        icon: global.icons + "ondemand.png",
        serial: "03",
        title: "On Demand",
        description:
          "On Demand Platform for B2B customers assisting in their deliveries through 100% EV Fleet around  with 600+ happy customers.",
      },
    ],
  },

  estimation: {
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    solutions: [
      {
        serial: "01",
        icon: global.eicons + "pic1.png",
        title: "Solutions and specialized",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service",
      },
      {
        serial: "02",
        icon: global.eicons + "pic2.png",
        title: "Multiple warehouses",
        description:
          "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind...",
      },
      {
        serial: "03",
        icon: global.eicons + "pic3.png",
        title: "Tracking made easy",
        description:
          "A tracking number for the entire process. so that you can find the exact position.",
      },
    ],
  },

  vehicles: {
    title: "Our Fleets",
    subtitle: "3W EV & 4W EV",
    description:
      "EV Logistics is trusted for delivering sustainable solutions through our core services: eco-friendly warehousing, on-demand B2B customer support, and tailored corporate logistics.",
    vehicles: [
      {
        title: "Log 9 (3W)",
        serial: "",
        image: global.fleet + "1.png",
        // description: "Assisting Corporate customers to manage their end mile deliveries through EV’s and Our team (Drivers and Delivery Boys)."
      },
      {
        title: "11 Feet (Closed Body)",
        serial: "",
        image: global.fleet + "2.png",
      },
      {
        title: "11 Feet (Open Body)",
        serial: "",
        image: global.fleet + "3.png",
      },
    ],
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Current Operations",
    subtitle: "Booking For Product Transformation",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png",
    },
  },
  battery: {
    background: global.banner + "battery.png",
  },

  plans: {
    title: "Our Plan",
    subtitle: "The right price for you whoever you are",
    plans: [
      {
        type: "Startup",
        price: "90$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
      {
        type: "Standard",
        price: "150$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
      {
        type: "Business",
        price: "260$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
    ],
  },
};
