export default function RefundPolicy() {
  return (
    <>
      {/* <Banner _data={data.banner} /> */}
      <div className="privacy-policy-container">
        <h1>Refund Policy</h1>

        <h2>1. Eligibility for Refunds</h2>
        <p>
          We strive to provide seamless and reliable logistics services.
          However, refunds may be issued under the following circumstances:
          <ul>
            <li>
              Service cancellation by the company due to unforeseen
              circumstances.
            </li>
            <li>
              Failed or undelivered shipments due to our operational issues.
            </li>
            <li>Excess payment or duplicate transactions.</li>
          </ul>
        </p>
        <h2>2. Non-Refundable Cases</h2>
        <p>
          Refunds will not be applicable in the following situations:
          <ul>
            <li>
              Delay caused by external factors (weather, traffic, government
              regulations, etc.).
            </li>
            <li>
              Incorrect details provided by the customer (wrong address, contact
              number, etc.).
            </li>
            <li>
              Cancellation of the order after the shipment has been dispatched.
            </li>
            <li>Customer unavailability at the delivery location.</li>
          </ul>
        </p>
        <h2>3. Refund Process</h2>
        <p>
          <ul>
            <li>
              Refund requests must be made within 4-6 days from the date of the
              issue.
            </li>
            <li>
              Customers must provide proof of payment and a valid reason for the
              refund request.
            </li>
            <li>
              All refund requests can be initiated by contacting our support
              team at <u>contact@pickkup.io</u>
            </li>
          </ul>
        </p>
        <h2>4. Refund Timeline</h2>
        <p>
          <ul>
            <li>
              Approved refunds will be processed within 4-6 business days.
            </li>
            <li>
            Approved refunds will be credited to the user's account within 5-7 business days.
            </li>
            <li>
              Refunds will be credited to the original payment method or as a
              wallet credit for future use
            </li>
          </ul>
        </p>
       
        <h2>5. Contact Us</h2>
        <p>
          For any refund-related queries, please reach out to us at 70682-90682.
        </p>
      </div>
    </>
  );
}
